/*
 * Accordion
 */

$('.accordion__item.active').find('.accordion__item__content').show();
$('.accordion__item__title').on('click', function(e) {
    e.preventDefault();
    var $accordionItem = $(this).closest('.accordion__item');
    $accordionItem.toggleClass('active');
    $accordionItem.siblings().find('.accordion__item__content').hide();
    var $accordionContent = $(this).next('.accordion__item__content');
    $accordionContent.toggle();
    setTimeout(function(){
        $accordionContent.find('input:not([type="hidden"])').eq(0).focus();
    }, 300);
});

var $invoicePalServiceFees = $('.product__quantity span:nth-child(2)');
var $invoicePalServiceFeesAlert = $('.free_service_fees_alert');
var click2payServiceFeesFreeUpTo = $('.pal_service_fees_free_up_to').text() ? parseInt($('.pal_service_fees_free_up_to').text()) : 0;

$('input.optIn').change(function() {
  $(this).closest('div.input_wrapper').next('div').toggle();

  if($(this).closest('.input_wrapper').prev().find('.quantity').val() < click2payServiceFeesFreeUpTo) {
    $invoicePalServiceFees.show();
    $invoicePalServiceFeesAlert.show();
  } else {
    $invoicePalServiceFees.hide();
    $invoicePalServiceFeesAlert.hide();
  }
}).change();

var $invoiceTotalQuantity = $('.product__quantity span:nth-child(1)');
var productUnitAmount = parseFloat($('.invoice_product_price').text());
var click2payServiceFees = $('.pal_service_fees').text() ? parseFloat($('.pal_service_fees').text()) : '';

$('.quantity').on('change paste keyup', function() {
  var quantity = parseInt($(this).val(), 10);

  if(quantity > 0) {
    var newInvoiceTotalAmount = parseInt(Math.round(quantity * productUnitAmount, 2).toFixed(0));

    if(click2payServiceFeesFreeUpTo == '' || (click2payServiceFeesFreeUpTo !== '' && quantity < click2payServiceFeesFreeUpTo)) {
      if(click2payServiceFees > 0) {
        newInvoiceTotalAmount += click2payServiceFees;
        $invoicePalServiceFees.show();
        $invoicePalServiceFeesAlert.show();
      }
    } else {
      $invoicePalServiceFees.hide();
      $invoicePalServiceFeesAlert.hide();
    }

    $invoiceTotalQuantity.html(quantity);
    $('.product__price span').html(number_format(newInvoiceTotalAmount, 0, '.', ' '))
    $('.product__price').addClass("animated tada");
  }
});

function number_format (number, decimals, dec_point, thousands_sep) {
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
    s = '',
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return '' + Math.round(n * k) / k;
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}