function displaySweetAlertMessage(title, message, timer, type) {
    var options = {
        title: title,
        text: message,
        html: true
    };

    if (type) {
        options.type = type;
    }

    if (timer) {
        options.timer = timer;
        options.showConfirmButton = false;
    }

    swal(options);
}

window.displaySweetAlertMessage = displaySweetAlertMessage;